ul.amazon-list {
  @include clearfix();

  width: 100%;
  list-style-type: none;
  margin: 0;

  > li {
    @include clearfix();

    display: block;
    border-color: $color-lightgrey;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    margin-bottom: 21px;
    text-align: center;

    @media #{$medium-up} {
      width: 50%;
      float: left;
      text-align: left;
    }

    > h1, > h2, > h3, > h4, > .headline {
      font-weight: bold;
      display: block;
      margin-bottom: 13px;
      font-size: 11pt;

      @media #{$medium-up} {
        font-size: 14pt;
      }
    }

    > a.image-link {
      display: block;

      @media #{$medium-up} {
        float: left;
        margin-right: 21px;
        width: 30%;
        max-width: 150px;
      }

      > img {
        max-width: 50%;

        @media #{$medium-up} {
          max-width: 100%;
          max-height: 200px;
        }
      }
    }

    > .price {
      display: block;

      &:before {
        content: 'Preis: ';
      }

      @media #{$medium-up} {
        margin-top: 21px;
      }

      @media #{$large-up} {
        margin-top: 55px;
      }
    }

    > .amazon-link {
      display: inline-block;

      @media #{$medium-up} {
        margin-bottom: 21px;
      }
    }
  }

  &.small {
    display: block;

    > li {
      margin-bottom: 5px;
      width: 50%;
      float: left;
      text-align: left;

      @media #{$small-only} {
        text-align: center;

        .image-link {
          display: inline;
        }
      }

      > h1, > h2, > h3, > h4, > .headline {
        font-size: 9pt;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 90%;
      }

      > a.image-link {
        width: 70px;
        text-align: center;
        margin-bottom: 5px;
        margin-right: 5px;

        > img {
          max-height: 50px;
        }
      }

      > .price {
        margin-top: 0;
        font-size: 9pt;

        &:before {
          content: '';
        }
      }

      > .amazon-link {
        padding: 0;
        font-size: 9pt;
        margin-top: 2px;
        margin-bottom: 5px;
        box-shadow: none;
        background-color: $color-white;
        color: $color-darkpink;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
        }
      }

      &:nth-last-child(+2), &:last-child {
        border:none;
      }
    }
  }
}
