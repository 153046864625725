header {
  $brand-width-mobile: rem-calc(120);
  $brand-width: rem-calc(180);
  $mobile-menu-width: rem-calc(40);

  $search-input-height: rem-calc(37);
  $search-input-left-padding-mobile: rem-calc(22px);
  $search-input-left-padding: $spacing34;
  $search-input-top-margin: rem-calc(15px);

  div.top-bar {
    @include clearfix();

    max-width: $row-width;
    margin: auto;
    height: $header-top-bar-height;
    padding: 0 #{$column-gutter / 2} 0 #{$column-gutter / 2};

    > div {
      float: left;
      height: $header-top-bar-height;
      white-space: nowrap;

      &.brand {
        width: $brand-width-mobile;

        @media #{$medium-up} {
          width: $brand-width;
        }

        > * {
          margin: 0;
          line-height: $header-top-bar-height;
          height: $header-top-bar-height;
          position: relative;
          display: block;

          > a {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            > img {
              position: absolute;
              height: 50%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);

              @media #{$medium-up} {
                height: 70%;
              }
            }
          }
        }
      }

      &.search {
        width: calc(100% - #{$brand-width-mobile + $mobile-menu-width});
        padding-left: $spacing5;

        @media #{$medium-up} {
          width: calc(100% - #{$brand-width + $mobile-menu-width});
          padding-left: $spacing34;
        }

        @media #{$large-up} {
          width: calc(100% - #{$brand-width});
        }

        > form {
          line-height: $header-top-bar-height;
          height: $header-top-bar-height;
          position: relative;

          > input {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: $search-input-height;
            line-height: $search-input-height;
            width: 100%;
            padding-left: $search-input-left-padding-mobile;
            background-color: $color-lightgrey;
            border: none;

            @media #{$medium-up} {
              padding-left: $search-input-left-padding;
            }

            &:focus {
              outline: none;
            }

            + .suggestions {
              position: absolute;
              top: calc(50% + #{($search-input-height - rem-calc(2)) / 2});
              list-style-type: none;
              z-index: index($zIndexElements, search-suggestions);
              background-color: $color-white;
              margin: 0;
              padding: 0;
              width: 100%;
              border-width: 0 1px 1px 1px;
              border-style: solid;
              border-color: $color-lightgrey;

              > li {
                border-width: 1px 0 0 0;
                border-style: solid;
                border-color: $color-lightgrey;

                &:first-of-type {
                  border: none;
                }

                > a {
                  display: block;
                  padding: $spacing3 $spacing8;
                  color: $color-black;
                  width: 100%;
                  font-size: 60%;

                  @media #{$medium-up} {
                    padding: $spacing5 $spacing13;
                    font-size: 80%;
                  }

                  &:hover {
                    text-decoration: none;
                    background-color: $color-lightgrey;
                  }
                }
              }
            }
          }

          > button {
            @include has-icon('search', 0);

            padding: 0;
            color: $color-mint;
            background-color: transparent;
            position: absolute;
            left: #{$search-input-left-padding-mobile / 2};
            top: #{$search-input-top-margin + $search-input-height / 2};
            margin: 0;
            transform: translate(-50%, -50%);

            @media #{$medium-up} {
              left: #{$search-input-left-padding / 2};
            }

            > i {
              font-size: 130%;
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      &.mobile-menu {
        width: $mobile-menu-width;
        text-align: right;
        display: block;
        position: relative;

        @media #{$large-up} {
          display: none;
        }

        > a {
          @include has-icon('bars', 0);

          position: absolute;
          top: #{$search-input-top-margin + $search-input-height / 2};
          right: 0;
          display: inline-block;
          transform: translateY(-50%);
          font-size: 0;

          > i {
            vertical-align: top;
            font-size: rem-calc(26);
          }
        }
      }
    }
  }
}
