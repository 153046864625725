section.product-line {
  max-width: none;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: $spacing13;

  @media #{$medium-up} {
    background-color: $lightgrey;
    margin-bottom: $spacing13;
  }

  > nav.slider {
    @include grid-row();

    @media #{$medium-up} {
      padding-left: $spacing13;
      padding-right: $spacing13;
    }

    > .slides {
      width: auto;

      > .slide {
        width: 35vw;
        margin-bottom: 0;

        &:first-of-type {
          @media #{$medium-up} {
            margin-left: 0;
          }
        }

        @media #{$medium-up} {
          width: calc(15% - #{$column-gutter / 2});
        }

        > a {
          display: block;
          width: 100%;
          height: 100%;

          > img {
            width: auto;
            height: 100px;
          }
        }
      }
    }
  }
}
