section.filters-products {
  > form {
    display: block;
    margin-bottom: $spacing13;
    border-width: 0 0 1px 0;
    border-color: $color-lightgrey;
    border-style: solid;

    &.visible {
      display: block;
    }

    > .filters {
      margin-bottom: $spacing8;

      @media #{$large-up} {
        margin-bottom: $spacing21;
      }

      &:after {
        content: ' ';
        display: block;
        clear: both;
      }

      > .dropdown {
        $height: rem-calc(40);
        $submit-button-height: rem-calc(35);
        $search-input-height: rem-calc(30);
        $search-input-margin-bottom: $spacing5;
        $list-padding: $spacing8;
        $border-width: rem-calc(1);

        border-color: $color-mediumgrey;
        border-style: solid;
        border-width: $border-width 0 $border-width $border-width;
        position: relative;
        transition: border-width 0.1s;
        width: 20%;
        float: left;
        height: $height;
        box-sizing: border-box;
        font-size: 80%;

        &:last-of-type {
          border-width: $border-width;
        }

        @media #{$medium-up} {
          font-size: 100%;
          border-width: $border-width;
          border-radius: $border-radius;
          width: calc(25% - #{$spacing8 * 4 / 5});
          margin-bottom: $spacing8;
          margin-right: $spacing8;

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @media #{$large-up} {
          width: calc(20% - #{$spacing8 * 4 / 5});

          &:nth-child(4n) {
            margin-right: $spacing8;
          }

          &:nth-child(5n) {
            margin-right: 0;
          }
        }

        &:last-child {
          border-width: $border-width;
        }

        &.expanded {
          border-width: $border-width $border-width 0 $border-width;
          border-radius: $border-radius $border-radius 0 0;
          height: auto;

          @media #{$small-only} {
            position: fixed;
            width: 90%;
            height: 80%;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            background-color: $color-white;
            z-index: index($zIndexElements, mobile-dropdown-layer);
            border-radius: #{$border-radius * 4};
            overflow: hidden;
          }

          > .label {
            @include has-icon('caret-up', 0);

            @media #{$small-only} {
              > i {
                font-size: 180%;
                &:before {
                  content: '';
                }
              }

              > .name {
                font-weight: bold;
                font-size: 150%;
              }
            }
          }

          > .list {
            pointer-events: auto;
            opacity: 1;
            display: block;

            @media #{$small-only} {
              height: calc(100% - #{$height});
              border: none;

            }

            > ul {
              @media #{$small-only} {
                -webkit-overflow-scrolling: touch;
                max-height: none;
                height: calc(100% - #{$submit-button-height
                  + $search-input-height
                  + $search-input-margin-bottom
                  + $list-padding * 2
                });
              }
            }
          }
        }

        &.genders {
          > .list {
            > .search {
              display: none;
            }

            > ul {
              @media #{$small-only} {
                -webkit-overflow-scrolling: touch;
                max-height: none;
                height: calc(100% - #{$submit-button-height + $list-padding * 2});
              }
            }
          }
        }

        &.colors {
          > .list {
            > .search {
              display: none;
            }

            > ul {
              @media #{$small-only} {
                -webkit-overflow-scrolling: touch;
                max-height: none;
                height: calc(100% - #{$submit-button-height + $list-padding * 2});
              }

              > li {
                display: inline-block;
                width: 25%;
                text-align: center;

                @media #{$medium-up} {
                  text-align: left;
                }

                &[data-value=white] {
                  >input {
                    &:checked {
                      + label:before {
                        background-image: url(/img/icons/check.png);
                      }
                    }
                  }
                }

                > input {
                  &:checked {
                    + label:before {
                      display: block;
                      border: none;
                      margin: 0;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      background-color: transparent;
                      background-image: url(/img/icons/check_white.png);
                      background-size: 50% 50%;
                      background-position-x: center;
                      background-position-y: center;
                      background-repeat: no-repeat;
                    }
                  }
                }

                > label {
                  display: inline-block;
                  font-size: 0;
                  width: 12vw;
                  height: 12vw;
                  max-height: 50px;
                  max-width: 50px;
                  border-radius: 50%;
                  background-color: black;
                  padding: 0;

                  @media #{$medium-up} {
                    width: 20px;
                    height: 20px;
                  }

                  &:before {
                    display: none;
                  }
                }

                &[data-value=blue] {
                  > label {
                    background-color: #6787C6;
                  }
                }

                &[data-value=brown] {
                  > label {
                    background-color: #A24A22;
                  }
                }

                &[data-value=cyan] {
                  > label {
                    background-color: #4BC7BD;
                  }
                }

                &[data-value=gray] {
                  > label {
                    background-color: #C8C8C8;
                  }
                }

                &[data-value=green] {
                  > label {
                    background-color: #19A616;
                  }
                }

                &[data-value=nude] {
                  > label {
                    background-color: #F7D8C0;
                  }
                }

                &[data-value=nude] {
                  > label {
                    background-color: #F7D8C0;
                  }
                }

                &[data-value=orange] {
                  > label {
                    background-color: #FF9E41;
                  }
                }

                &[data-value=pink] {
                  > label {
                    background-color: #F31BF7;
                  }
                }

                &[data-value=purple] {
                  > label {
                    background-color: #790BCD;
                  }
                }

                &[data-value=red] {
                  > label {
                    background-color: #D93830;
                  }
                }

                &[data-value=white] {
                  > label {
                    background-color: #FFFFFF;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $darkgrey;
                  }
                }

                &[data-value=yellow] {
                  > label {
                    background-color: #FAED1C;
                  }
                }
              }
            }
          }
        }

        > .label {
          @include has-icon('caret-down', 0);

          display: block;
          width: 100%;
          height: #{$height - 2 * $border-width};
          position: relative;
          padding: $spacing8;
          box-sizing: border-box;
          cursor: pointer;

          > .name {
            font-size: 80%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            @media #{$medium-up} {
              left: $spacing8;
              top: 52%;
              transform: translateY(-50%);
            }
          }

          > i {
            position: absolute;
            right: rem-calc(15);
            font-size: 100%;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            color: $color-grey;

            @media #{$medium-up} {
              display: block;
            }
          }
        }

        > .list {
          transition: opacity 0.3s;
          pointer-events: none;
          overflow: hidden;
          position: absolute;
          top: $height - $border-width * 2;
          left: -1px;
          right: -1px;
          z-index: index($zIndexElements, dropdown);
          background-color: $white;
          border-width: 0 1px 1px 1px;
          border-style: solid;
          border-color: $color-mediumgrey;
          border-radius: 0 0 $border-radius $border-radius;
          opacity: 0;
          padding: $list-padding $list-padding 0 $list-padding;

          > input {
            width: 100%;
            box-sizing: border-box;
            height: $search-input-height;
            line-height: $search-input-height;
            font-size: 80%;
            padding: $spacing5;
            margin-bottom: $search-input-margin-bottom;

            &:focus {
              outline: none;
            }
          }

          > ul {
            font-size: 0;
            list-style-type: none;
            max-height: 30vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0;
            margin: 0;

            > li {
              padding: $spacing3 0 $spacing3 0;
              font-size: rem-calc(14);

              @media #{$medium-up} {
                padding-bottom: #{$list-padding + $spacing3};
              }

              &.hidden {
                display: none;
              }

              > label {
                margin: 0;

                @media #{$medium-up} {
                  font-size: 80%;
                }
              }
            }
          }

          > button {
            width: 100%;
            background-color: $color-darkpink;
            color: $color-white;
            margin-bottom: $list-padding;
            height: $submit-button-height;
            font-weight: bold;

            @media #{$medium-up} {
              font-weight: normal;
            }
          }
        }
      }
    }

    > .remove {
      margin: $spacing13 auto $spacing8 auto;
      font-size: 80%;

      > a {
        @include has-icon('remove', 0);

        display: inline-block;
        background-color: $rose;
        border-radius: $border-radius;
        padding: $spacing3 $spacing21 $spacing3 $spacing5;
        position: relative;
        margin-right: $spacing13;

        &:hover {
          text-decoration: none;
        }

        > i {
          position: absolute;
          right: $spacing5;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
