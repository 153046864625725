@import "header/social-bar";
@import "header/top-bar";
@import "header/category-bar";

body {
  > header {
    @include box-shadow();

    width: 100%;
    top: 0;
    box-sizing: content-box;
    background-color: #ffffff;
    z-index: index($zIndexElements, header);
    transition: transform 0.3s;

    @media #{$large-up} {
      height: $header-height-desktop;
      position: fixed;
    }
  }

  &.has-flash, &.has-js-flash {
    > header {
      height: $header-height-desktop + $flash-height;
    }
  }

  &.sticky-header {
    > header {
      height: auto;
      opacity: 0.99;

      .info-bar {
        display: none;
      }

      nav {
        &.top-bar {
          &:not(.expanded) {
            height: $topbar-height-sticky;
          }

          > section {
            > ul {
              > li:not(.has-form) {
                @media #{$medium-up} {
                  height: $topbar-link-height-sticky;
                  margin-top: ($topbar-height-sticky - $topbar-link-height-sticky)/2;
                  margin-bottom: ($topbar-height-sticky - $topbar-link-height-sticky)/2;

                  &:before {
                    height: $topbar-link-height-sticky;
                    line-height: $topbar-link-height-sticky;
                  }

                  > a:not(.has-button) {
                    font-weight: normal;
                    height: $topbar-link-height-sticky;
                    line-height: $topbar-link-height-sticky;
                  }

                  &.search {
                    > form {
                      height: $topbar-height-sticky;

                      > input {
                        height: $topbar-height-sticky;
                      }
                    }

                  }

                  &.register {
                    a {
                      border-width: 0;
                      padding-right: 0;
                      height: $topbar-link-height-sticky !important;
                      margin-top: 0;
                    }
                  }
                }

                &.name {
                  height: $topbar-height-sticky;

                  a {
                    background-image: url(/img/logo_small.png);
                    background-size: $spacing8*-1+$topbar-height-sticky $spacing8*-1+$topbar-height-sticky;
                    height: $topbar-height-sticky;
                    width: $topbar-height-sticky;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.header-hidden {
    > header {
      transform: translateY(-100%);
    }
  }
}
