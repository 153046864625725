div.dropdown {
  position: relative;
  height: $dropdown-height;
  .title {
    @include has-dropdown-arrow();
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    height: $dropdown-height;
    line-height: $dropdown-height;
    padding-left: $spacing21;
    border-width: 1px;
    border-style: solid;
    border-color: $darkgrey;
    overflow: hidden;
    position: relative;

    > i {
      position: absolute;
      right: $spacing21;
      line-height: $dropdown-height;
    }
  }

  .content {
    display: none;
    overflow: hidden;
    background-color: #ffffff;
    position: absolute;
    text-align: left;
    width: 100%;
    z-index: index($zIndexElements, dropdown);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $darkgrey;
    top: $dropdown-height;

    > ul {
      list-style-type: none;
      margin: $spacing13 $spacing34 $spacing13 $spacing34;
      max-height: rem-calc(250);
      overflow-y: auto;

      &.select {
        @include block-grid(2);
        margin: 0 $spacing34 $spacing13 $spacing34;
        margin-top: $spacing8;
        border-style: solid;
        border-width: 0;
        border-top-width: 1px;

        > li {
          padding: 0;
          padding-top: $spacing21;

          &:last-of-type {
            text-align: right;
          }
        }
      }
    }

    > button[type=submit] {
      @include has-icon('check');

      display: none;
      background-color: $black;
      color: $white;
      border-color: $black;
      border-width: 1px 0 0 0;
      margin: auto;
      width: 100%;

      @media #{$medium-up} {
        display: block;
      }
    }
  }
}