body {
  main.wordpress_article {
    > section {
      article.post {
        .wc-gallery {
          .wcflexslider {
            .slides  {
              img {
                width: 100% !important;
              }
            }

            .wcflex-prev {
              opacity: 0.5;
            }

            .wcflex-next {
              opacity: 0.5;
            }

            &:hover {
              .wcflex-prev {
                opacity: 1;
              }

              .wcflex-next {
                opacity: 1;
              }
            }

            .wcflex-control-paging {
              display: none;
              bottom: $spacing13;
              right: $spacing21;
              width: auto;
              line-height: 1;

              > li {
                line-height: 1;
                margin: 0;

                &:before {
                  content: '•';
                  color: #878787;
                  font-size: 80%;
                }

                &:first-of-type {
                  &:before {
                    content: '';
                  }
                }

                > a {
                  display: inline-block;
                  line-height: 1;
                  color: #878787;
                  text-indent: 0;
                  background: none;
                  width: auto;
                  height: auto;
                  padding: 0 $spacing5 0 $spacing5;

                  &.wcflex-active {
                    font-weight: bold
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}