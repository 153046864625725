ul.interest-choices {
  @include block-grid(4);

  > li {
    > input {
      &:checked {
        + label > .thumbnail {
          > i {
            color: #ffffff;
            display: inline;
          }

          background-color: #000000;
          border-width: 2px;
          border-style: solid;
          border-color: #000000;
        }
      }
    }

    label {
      display: block;
      width: 100%;
      padding: 0;
      text-transform: uppercase;
      text-align: center;

      &:before {
        display: none;
      }

      > .thumbnail {
        @include has-icon(
          $icon: 'check',
          $margin: 0,
          $display: none
        );

        width: 100%;
        border-width: 2px;
        border-color: #000000;
        height: rem-calc(60);
        line-height: rem-calc(60);
        background-color: #737373;
        background-position: center;
        text-align: center;
        font-size: 125%;
        margin-bottom: $spacing8;
      }
    }

    &.frauen {
      label {
        > .thumbnail {
          background-image: url(/img/signup/frauen.png);
        }
      }
    }

    &.maenner {
      label {
       > .thumbnail {
          background-image: url(/img/signup/maenner.png);
        }
      }
    }

    &.home {
      label {
        > .thumbnail {
          background-image: url(/img/signup/home.png);
        }
      }
    }

    &.blog {
      label {
        > .thumbnail {
          background-image: url(/img/signup/blog.png);
        }
      }
    }
  }
}