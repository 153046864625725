main section.team {
  @include full-width();
  @include jumbotron($spacing55 0 0 0);

  background-image: url(/img/team.jpg);
  background-size: cover;
  padding-bottom: 0;

  h2 {
    color: #ffffff;
  }

  > ul {
    @include block-grid(1);

    @media #{$medium-up} {
      @include block-grid(3);
      margin: auto;
    }

    margin: auto;
    max-width: rem-calc(800);
    margin-top: $spacing55;

    li {
      height: rem-calc(420);
      background-size: cover;
      background-position: center top;
      position: relative;
      padding: 0;

      &.brian {
        background-image: url(/img/team/brian.jpg);
      }

      &.maria {
        background-image: url(/img/team/maria.jpg);

        @media #{$medium-up} {
          height: rem-calc(440);
          margin-top: $spacing21 * -1;
        }
      }

      &.jerome {
        background-image: url(/img/team/jerome.jpg);
      }

      .content {
        background-image: url(/img/team/overlay.png);
        background-size: 100% 100%;
        position: absolute;
        width: 100%;
        height: 100%;

        > h3 {
          text-transform: uppercase;
          color: #ffffff;
          display: block;
          width: 100%;
          text-align: center;
          position: absolute;
          bottom: $spacing21;
        }
      }
    }
  }
}