html {
  &.no-touch {
    a {
      &:hover {
        color: $color-darkpink;
        text-decoration: underline;
      }
    }
  }
}

a {
  color: $color-darkpink;
  text-decoration: none;

  &.disabled {
    opacity: 0.5;

    &:hover {
      color: #000000 !important;
    }
  }
}

button {
  text-transform: uppercase;
  border-radius: $border-radius;
  background-color: $rose;
  border-color: $darkpink;
  font-size: 11px;
  padding: 10px 15px;

  &:hover {
    background-color: $pink;
  }

  &:focus, &:active {
    outline: none;
  }
}

input {
  &[type=text], &[type=password], &[type=email] {
    @include border-width();

    border-radius: $border-radius;
    border-style: solid;
    border-color: $color-grey;
    height: $input-height;
    line-height: $input-height;

    &:focus {
      outline: none;
    }
  }
}

input[type=checkbox] {
  border-width: $border-width;
  border-style: solid;
  border-color: #000000;
  display: none;

  &:checked {
    + label:before {
      background-color: black;
      background-image: url(/img/icons/check_white.png);
      background-size: 75% 75%;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
    }
  }
}

input[type=checkbox] + label {
  position: relative;
  padding-left: rem-calc(25);
  font-size: rem-calc(16);
  margin-left: 0;
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(10);

  &:before {
    @include border-width();

    content: "";
    display: inline-block;
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    font-weight: bold;
    text-align: center;
    color: #000000;
    width: rem-calc(15);
    height: rem-calc(15);
    margin-right: rem-calc(10);
    position: absolute;
    left: 0;
    top: 55%;
    transform: translateY(-50%);
    border-style: solid;
    border-color: #000000;
    border-radius: 3px;
  }
}

label {
  > input[type=text], > input[type=password], > input[type=email] {
    margin-top: $spacing5;
  }
}

.error {
  > textarea, input[type=text], input[type=password], input[type=email] {
    color: $error-color;
    border-color: $error-color !important;
    background-image: url(/img/icons/error.png);
    background-size: auto $input-height/100*30;
    background-position: right $spacing13 bottom $spacing13;
    background-repeat: no-repeat;



    &+.password-toggle {
      display: none;
    }

    &.touched {
      color: #000000;
      background-image: none;

      &+.password-toggle {
        display: block;
      }
    }
  }

  &textarea {
    background-position: right $spacing13 bottom 10%;
  }
}

.unselectable {
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.load-more {
  text-align: center;

  > ul {
    @include block-grid(
      $per-row: 1,
      $spacing: rem-calc(16)
    );

    @media #{$medium-up} {
      @include block-grid(
        $per-row: 3,
        $spacing: rem-calc(16)
      );
    }

    > li {
      margin: 0;
    }
  }

  @media #{$medium-up} {
    margin-top: $spacing21;
  }

  &.loading {
    button {
      @include has-icon('spinner');

      i {
        @extend .icon-spin;
      }
    }
  }

  button {
    @include has-icon('th-large');
    width: 100%;
  }
}

i.clipboard {
  @include has-icon('copy');

  display: none;
  color: inherit;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1.0;
  }
}

html {
  &.clipboard-supported {
    .clipboard {
      display: inline;
    }
  }
}
html, body, main {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  padding-bottom: $footer-height;
  height: auto;
  min-height: 100%;
  background-color: $white;
  line-height: 1.5;

  > main {
    @include full-width;

    display: block; // IE&Edge don't consider <main> as block by default
    margin-top: $spacing21;
    overflow: visible;

    &.home {
      margin-top: 0;

      @media #{$large-up} {
        margin-top: $header-height-desktop;
      }
    }

    @media #{$large-up} {
      margin-top: #{$header-height-desktop + $spacing21};
    }

    > nav {
      padding-left: $spacing13;
      padding-right: $spacing13;
    }

    > section {
      @include grid-row(collapse);

      padding: $spacing13 #{$column-gutter / 2} 0 #{$column-gutter / 2};
      margin: auto;

      &:first-of-type {
        padding-top: 0;
      }

      > h2, > form > h2 {
        color: $header-font-color;
        text-decoration: underline;
        margin-bottom: rem-calc(18);
        margin-left: ($column-gutter / 2);
        margin-right: ($column-gutter / 2);
        font-size: rem-calc(26);

        @media #{$medium-up} {
          font-size: rem-calc(30);
        }
      }

      > .notice {
        @include alert(
            $bg: $shops-color
        );
        margin-left: ($column-gutter / 2);
        margin-right: ($column-gutter / 2);
      }
    }
  }

  &.has-flash {
    > main {
      padding-top: $header-height-desktop + $flash-height;
    }
  }
}

hr {
  @include border-width(0, 0, $border-width, 0);
  border-style: solid;
  border-color: #d0d1d1;
  margin-top: $spacing8;
  margin-bottom: $spacing8;

  @media #{$medium-up} {
    margin-top: $spacing21;
    margin-bottom: $spacing34;
  }
}

section {
  > hr {
    margin-left: -5000px;
    width: 10000px;
    display: block;
  }
}

[data-sticky] {
  display: none;
  visibility: hidden;

  @media #{$medium-up} {
    display: block;
  }
}

img {
  &[data-lazy] {
    transition: opacity 0.2s linear;
    opacity: 0;
  }
}

[data-href] {
  cursor: pointer;
}
