header .flash {
  height: $flash-height;
  top: 0;
  width: 100%;
  display: table;

  @media #{$large-up} {
    font-size: 120%;
  }

  .message {
    @include grid-row();
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding-left: $spacing8;
    padding-right: $spacing34;

    .close {
      @include has-icon('close', 0);

      position: absolute;
      right: $spacing13;
      top: 0;
      line-height: $flash-height;
    }
  }

  &.danger {
    background-color: $error-color;
  }

  &.success {
    background-color: $success-color;
  }

  padding-left: $spacing5;
  padding-right: $spacing5;

  @media #{$medium-up} {
    padding-left: 0;
    padding-right: 0;
  }
}