nav.off-canvas {
  $item-height: rem-calc(45);

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: index($zIndexElements, off-canvas);
  pointer-events: none;
  background-color:transparent;

  &.visible {
    transition: background-color 0.3s;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    pointer-events: auto;

    > ul {
      transition: transform 0.3s;
      transform: none;
    }
  }


  > .close-area {
    z-index: index($zIndexElements, off-canvas-close);
    position: fixed;
    width: 100%;
    height: 100%;
  }

  ul {
    @include box-shadow($h-shadow: -1px, $v-shadow: 0);

    transform: translateX(100%);
    background-color: $color-white;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 250px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: index($zIndexElements, off-canvas-list);
    overflow-y: auto;
    overflow-x: hidden;

    li {
      height: $item-height;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $color-rose;
      padding: 0;

      &.sidebar-text {
        padding:  #{$column-gutter / 2} #{$column-gutter / 2} 0 #{$column-gutter / 2};
        line-height: 2.2;

        .highlight, .marker {
          color: $color-mint;
        }

        p {
          font-size: 80%;
          line-height: 2.2;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
        border-width: 0;
      }

      &.close {
        text-align: right;
        > a {
          @include has-icon('close', 0);

          display: inline-block;
          width: auto;
          text-align: right;
          font-size: rem-calc(26);

          > i {
            color: $color-darkpink;
          }
        }
      }

      &.headline {
        font-weight: bold;
      }

      > a, > span  {
        @include has-icon('angle-right', 0);

        display: inline-block;
        width: 100%;
        height: $item-height;
        line-height: $item-height;
        padding: 0 #{$column-gutter / 2} 0 #{$column-gutter / 2};
        position: relative;
        margin: 0;
        font-size: 80%;
        color: $color-darkpink;

        > i {
          display: none;
          position: absolute;
          right: #{$column-gutter / 2};
          top: 50%;
          transform: translateY(-50%);
          color: $color-darkpink;
        }
      }

      &.has-sub-menu {
        > a, > .span {
          padding-right: $spacing34;

          > i {
            display: block;
          }
        }

        > .sub-menu {
          transition: transform 0.3s;
          position: absolute;
          left: 0;
          top: $item-height;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }

      &.sub-menu-visible {
        > .sub-menu {
          transform: none;
        }
      }
    }
  }
}
