@mixin product($enableHover: true) {
  @include clearfix();

  $info-line-height: rem-calc(55);
  $price-width: rem-calc(50);
  $padding: $spacing8;
  $image-height: rem-calc(170);

  position: relative;
  overflow: hidden;
  display: block;
  background-color: $white;
  padding: $padding;
  border-width: 1px;
  border-style: solid;
  border-color: $color-lightgrey;
  border-radius: $border-radius;
  height: 100%;
  transition: border-color 0s, border-color 0s;

  * {
    pointer-events: none;
  }

  @if $enableHover {
    &:hover {
      transition: border-color 0.3s, box-shadow 0.3s;
      text-decoration: none;
      border-color: $color-mediumgrey;
      box-shadow: 0 0 rem-calc(10) 0 $color-mediumgrey;

      > .info {
        > .brand {
          width: calc(40% - #{$spacing5});
        }

        > .shop {
          display: block;
          width: 60%;
        }

        > .old-price {
          display: none;
        }
      }
    }
  }

  > .image-wrap {
    @include border-width();

    line-height: $image-height;
    height: $image-height;
    margin-bottom: $spacing13;
    text-align: center;
    vertical-align: middle;

    > img {
      width: auto;
      height: auto;
      z-index: index($zIndexElements, product-image);
      font-size: 0;
      margin: 0 auto $spacing8 auto;
      max-height: 100%;

      &.lazy-background {
        opacity: 0; // will be set to 1 by image lazy loading
      }

      &:before {
        display: block;
        content: ' ';
        padding-top: 125%;
        width: 100%;
      }
    }
  }



  > .sale {
    position: absolute;
    height: rem-calc(30);
    line-height: rem-calc(30);
    top: 0;
    right: 0;
    z-index: index($zIndexElements, product-sale);
    background-color: $shops-color;
    margin: 0;
    padding-left: $spacing8;
    padding-right: $spacing8;

    @media #{$medium-up} {
      height: rem-calc(40);
      line-height: rem-calc(40);
      padding-left: $spacing13;
      padding-right: $spacing13;
    }
  }

  > .info {
    @include clearfix();

    height: $info-line-height;

    > .brand {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
      font-size: rem-calc(11);
      width: calc(100% - #{$price-width + $spacing5});
      color: $color-black;
      float: left;
      display: inline-block;
      margin-right: $spacing3;
      margin-bottom: $spacing3;
      font-weight: bold;
    }

    > .old-price, > .shop {
      width: $price-width;
      float: left;
      overflow: hidden;
      font-size: rem-calc(11);
      white-space: nowrap;
      color: $color-black;
      text-align: right;
      margin-bottom: $spacing3;
    }

    > .shop {
      display: none;
    }

    > .old-price {
      text-decoration: line-through;
    }

    > .name {
      overflow: hidden;
      text-overflow: ellipsis;;
      text-align: left;
      white-space: nowrap;
      font-size: rem-calc(11);
      width: calc(100% - #{$price-width + $spacing5});
      color: $color-black;
      float: left;
      display: inline-block;
      margin-right: $spacing3;
      margin-bottom: $spacing3;
    }

    > .offer {
      width: $price-width;
      float: left;
      overflow: hidden;
      font-size: rem-calc(11);
      white-space: nowrap;
      text-align: right;
      font-weight: bold;

      > .price {
        color: $color-black;

        &.reduced {
          color: $color-darkpink;
        }
      }
    }

    > .sizes {
      clear: both;
      display: block;
      color: $color-grey;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: rem-calc(10);
      margin-top: $spacing5;
    }
  }
}
