section.category-text {
  h1, h2, h3, h4 {
    text-decoration: none;
    margin-top: 0;
    text-align: center;
  }
}

section.category-text {
  @include post();
}
