section.recommend {
  @include full-width();
  @include jumbotron();

  background-color: #232526;

  h2, h3 {
    color: #ffffff;
  }

  h2 {
    margin-bottom: $spacing13;
  }

  h3 {
    margin-bottom: $spacing55;
    text-transform: none;
  }

  > form {
    > .fields {
      @include grid-row();
      max-width: rem-calc(800);

      > div {
        input, textarea {
          @include placeholder-color(rgba(255, 255, 255, 0.8));

          width: 100%;
          border-style: solid;
          border-width: 2px;
          border-color: #ffffff;
          color: #ffffff;
          background-color: transparent;

          &.touched {
            color: #ffffff;
          }
        }

        > textarea {
          height: rem-calc(150);
        }

        > button {
          @include has-icon('heart-o');

          color: #000000;
          border-color: rgba(255, 255, 255, 0.9);
          background-color: rgba(255, 255, 255, 0.9);
          margin-top: $spacing34;
          margin-bottom: $spacing55;

          &:hover {
            border-color: #ffffff;
            background-color: #ffffff;

            i {
              color: $red;
            }
          }
        }
      }
    }
  }
}


section.recommend-partial {
  @include full-width();
  @include jumbotron();

  background-color: #232526;
  background-image: url(/img/recommend.jpg);
  background-size: cover;

  h2, h3 {
    color: #ffffff;
  }

  h2 {
    margin-bottom: $spacing13;
  }

  h3 {
    margin-bottom: $spacing55;
  }

  > a {
    @extend .button;
    @include has-icon('heart-o');

    color: #000000;
    border-color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;

    &:hover {
      border-color: #ffffff;
      background-color: #ffffff;
      color: #000000;

      i {
        color: $red;
      }
    }
  }
}