.saboxplugin-wrap {
  $gravatar-width: 100px;
  $gravatar-padding: 20px;

  .saboxplugin-authorname {
    font-weight: bold;
  }

  .saboxplugin-gravatar {
    padding: $gravatar-padding;

    > img {
      width: $gravatar-width;
    }
  }

  .vcard.author {
    width: calc(100% - #{$gravatar-width + $gravatar-padding * 2});
    border: none;
    padding: 0;
  }
}
