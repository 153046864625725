body {
  &.layer-open {
    max-height: calc(100% + 1px);
    overflow: hidden;
  }

  > .layer {
    position: fixed;
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: index($zIndexElements, layer);
    text-align: center;

    > .close-button {
      @include has-icon('close');
      position: absolute;
      font-size: 24pt;
      top: $spacing21;
      right: $spacing21;

      @media #{$medium-up} {
        top: $spacing55;
        right: $spacing55;
        font-size: 36pt;
      }
    }

    > form {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      max-width: rem-calc(500);
      width: 80%;
      margin: auto;

      > .headline {
        display: block;
        font-size: 20pt;

        @media #{$medium} {
          font-size: 28pt;
        }
      }

      > .subline {
        display: block;
        font-style: italic;

        font-size: 16pt;

        @media #{$medium} {
          font-size: 20pt;
        }
      }

      > input[type=email] {
        background-color: transparent;
        box-shadow: none;
        text-align: center;
        margin-top: $spacing34;
        margin-bottom: $spacing55;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
      }

      > button[type=submit] {
          text-transform: lowercase;
      }
    }
  }
}
