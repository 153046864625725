body {
  > footer {
    $nav-height: $footer-height / 2;
    $legal-height: $footer-height - $nav-height;

    background-color: $white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footer-height;
    overflow: hidden;
    text-align: center;

    nav {
      @include grid-row();

      ul {
        margin: auto;
        list-style-type: none;

        li {
          display: inline-block;
          padding: 0;
          line-height: $nav-height;
          max-width: 25%;
          overflow: hidden;
          padding-left: $spacing8;
          padding-right: $spacing8;

          @media #{$medium-up} {
            padding-left: $spacing13;
            padding-right: $spacing13;

            &:last-of-type {
              padding-right: 0;
            }
          }

          &:last-of-type {
            padding-right: 0;
          }

          a {
            font-size: 40%;
            text-transform: uppercase;
            color: #000000;

            @media #{$medium-up} {
              font-size: 80%;
            }

            @media #{$large-up} {
              font-size: 100%;
            }

            &.privacy {
              > .bestimmungen {
                display: none;

                @media #{$medium-up} {
                  display: inline;
                }
              }
            }

            &.terms {
              > .agb {
                display: inline;

                @media #{$medium-up} {
                  display: none;
                }
              }

              > .nutzungsbedingungen {
                display: none;

                @media #{$medium-up} {
                  display: inline;
                }
              }
            }
          }

          @media #{$large-up} {
            padding-left: 0;
            padding-right: $spacing55;
          }
        }
      }
    }

    .legal {
      height: $legal-height;
      color: $color-grey;

      font-size: 60%;

      @media #{$medium-up} {
        font-size: 80%;
      }
    }
  }
}
