main {
  > section.blog-teaser {


    background-color: $color-lightgrey;
    padding: $spacing13 #{$column-gutter / 2} #{$column-gutter / 2} #{$column-gutter / 2};
    margin-bottom: $spacing8;
    max-width: none;

    @media #{$medium-up} {
      padding: $spacing34 #{$column-gutter / 2} #{$column-gutter / 2} #{$column-gutter / 2};
      margin-bottom: $spacing13;
    }

    > h2 {
      text-decoration: none;
      max-width: $row-width;
      margin: auto;
      display: block;
      text-align: center;
      margin-bottom: $spacing13;

      @media #{$medium-up} {
        margin-bottom: $spacing34;
      }
    }

    > .excerpts {
      @include grid-row(collapse);

      margin: auto;
      display: flex;
      flex-wrap: wrap;

      > article {
        @include grid-column(6);

        @media #{$medium-up} {
          @include grid-column(4);
        }

        margin-bottom: $spacing34;
        text-align: center;

        > a {
          display: block;
          width: 100%;
          font-size: 0;
          padding-top: 66.67%;
          background-size: cover;
          background-position: center center;
          margin: 0 0 $spacing5 0;
          border-radius: $border-radius;

          @media #{$medium-up} {
            margin: 0 0 $spacing13 0;
          }
        }

        > .info {
          > header {
            > h3 {
              font-size: 80%;
              font-family: $font-family-sans-serif;
              font-weight: $font-weight-normal;

              > a {
                color: $color-darkgrey;
              }
            }
          }

          > footer {
            opacity: 0.8;
          }
        }
      }
    }

    > a {
      @include clearfix();
      @include button($radius: true);

      margin: $spacing21 0 0 0;
      text-transform: uppercase;
    }
  }
}
