.rp4wp-related-posts {
  @include border-width($border-width, 0, 0, 0);
  border-style: solid;
  border-color: #d0d1d1;
  margin-top: $spacing21;
  padding-top: $spacing21;

  > h3 {
    font-size: 120%;
    margin-bottom: $spacing21;
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }

    > li {
      display: inline-block;
      width: calc(50% - #{$column-gutter / 4});
      margin-left: $column-gutter / 2;
      margin-bottom: $spacing8;
      float: left;

      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }

      @media #{$medium-up} {
        width: calc(33.333% - #{$column-gutter / 3});
        margin-bottom: $spacing21;

        &:nth-of-type(2n + 1) {
          margin-left: $column-gutter / 2;
        }

        &:nth-of-type(3n + 1) {
          margin-left: 0;
        }
      }



      .rp4wp-related-post-image {
        width: 100%;
        overflow: hidden;
        padding-top: 66.67%;
        position: relative;
        margin-bottom: $spacing8;
        font-size: 0;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
        }
      }

      .rp4wp-related-post-content {
        text-align: center;

        > a {
          font-size: 100%;
          min-height: 48px;
          display: inline-block;
        }

        > p {
          display: none;
        }
      }
    }
  }
}