section.instagram {
  @include jumbotron();

  > ul {
    @include block-grid(1);

    width: 100%;
    margin: auto;

    @media #{$medium-up} {
      @include block-grid(3);
    }

    @media #{$large-up} {
      @include block-grid(3);
    }

    &.testimonials {
      li {
        img {
          @include box-shadow();
          margin-bottom: $spacing13;
        }

        h3 {
          text-transform: none;
        }

        span.description {
          font-size: 80%;
          display: inline-block;

          @media #{$medium-up} {
            margin-bottom: $spacing55;
          }
        }
      }
    }

    .follow {
      @extend .button;
      @include has-icon('instagram');

      margin-top: $spacing55;
      margin-bottom: $spacing144;
      text-transform: uppercase;
      width: 100%;
    }
  }
}