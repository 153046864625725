body {
  > header {
    nav.social-bar {
      background-color: $color-lightgrey;
      text-align: center;
      height: $header-social-bar-height;

      ul {
        @include grid-row();

        padding: 0;
        margin: 0 auto 0 auto;
        font-size: 0;

        li {
          text-transform: uppercase;
          font-size: rem-calc(11);
          line-height: $header-social-bar-height;

          @include has-icon('heart', 0 $spacing2 $spacing1 $spacing2);

          &:first-child {
            display: inline-block;
            text-align: left;
            padding-left: #{$column-gutter / 2};
            width: 100%;

            @media #{$medium-up} {
              width: 50%;
            }
          }

          &:last-child {
            text-align: right;
            padding-right: #{$column-gutter / 2};
            width: 50%;
            display: none;

            @media #{$medium-up} {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

header {



}