.count-sort {
  @include grid-row();

  margin: $spacing8 auto $spacing21 auto;

  > div {
    padding: 0;
    font-size: rem-calc(12);
    vertical-align: bottom;
    white-space: nowrap;
    line-height: 2;

    &.count {
      @include grid-column($columns: 4, $collapse: true);
    }

    &.sort {
      @include grid-column($columns: 8, $collapse: true);

      text-align: right;

      label {
        line-height: 1;

        select {
          width: auto;
          display: inline;
          border: none;
          outline: none;
          background-color: $white;
          font-weight: bold;
          padding: 0 $spacing21 0 0;
          height: auto;
          font-size: rem-calc(12);
          margin: 0 0 0 $spacing2;

          @media #{$medium-up} {
            margin: 0 0 0 $spacing8;
          }

          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}

