section.password, section.reset-password {
  @include box();

  button[type=submit] {
    @include has-icon('check', 0);
  }

  > .back {
    @include has-icon('angle-left');
    display: block;
    text-align: center;

    > a {
      font-weight: normal;
    }
  }
}

.password-toggle {
  @extend .unselectable;

  font-size: 150%;
  position: absolute;
  width: 50px;
  text-align: center;
}

input[type=password] + .password-toggle {
  @include has-icon('eye-slash', 0);
}

input[type=text] + .password-toggle {
  @include has-icon('eye', 0);
}