main {
  > section.register {
    @include box();
    max-width: rem-calc(800);

    div.type-choices {
      @include grid-column(12);
      padding: 0;
    }

    button[type=submit] {
      margin-top: $spacing21;
    }
  }
}