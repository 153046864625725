@mixin full-width {
  width: 100%;
  max-width: 100%;
}

@mixin column(
  $large: 12,
  $medium: 12,
  $small: 12
) {

  @media only screen and (max-width: $small-breakpoint) {
    @include grid-column($small)
  }

  @media only screen and (min-width: $small-breakpoint) and (max-width: $medium-breakpoint) {
    @include grid-column($medium)
  }

  @media only screen and (min-width: $medium-breakpoint) {
    @include grid-column($large)
  }
}

@mixin block-grid-margin(
  $per-row:false,
  $spacing:$block-grid-default-spacing,
  $include-spacing:true,
  $base-style:true,
  $margin:0) {
  @include block-grid(
          $per-row,
          $spacing,
          $include-spacing,
          $base-style);

  @if $per-row {
    &>li {
      $width: (100% - 2 * $per-row * $margin) / $per-row;
      width: $width;
      margin: $margin;
    }
  }
}

@mixin has-icon (
  $icon: 'circle',
  $margin: 0 rem-calc(10) rem-calc(3) 0,
  $font-size: inherit,
  $display: inline-block
) {
  > i {
    @extend .icon;
    @extend #{'.icon-'+$icon};
    margin: $margin;
    font-size: $font-size;
    display: $display;
    vertical-align: middle;
  }
}

@mixin has-dropdown-arrow(
  $margin: 0 0 3px 0,
  $direction: 'down'
) {
  @include has-icon('caret-' + $direction, $margin);

  position: relative;
}

@mixin box() {
  @include box-shadow();

  background-color: #ffffff;
  max-width: rem-calc(600);
  padding: $spacing21;
  padding-left: $spacing34;
  padding-right: $spacing34;
  margin-top: $spacing8;
  margin-bottom: $spacing8 + $footer-height;

  @media #{$medium-up} {
    margin-top: $spacing34;
    margin-bottom: $spacing34 + $footer-height;
    padding: $spacing55;
    padding-left: $spacing89;
    padding-right: $spacing89;
  }

  h2 {
    text-decoration: none;
    text-transform: none;
    margin-top: rem-calc(30);
    margin-left: 0;
    margin-right: 0;
    line-height: normal;

    &:first-of-type {
      margin-top: 0;
    }

    &:after {
      content: ' ';
      border-width: 0;
      border-bottom-width: rem-calc(2);
      border-style: solid;
      border-color: #000000;
      display: block;
      margin-top: rem-calc(15);
      margin-bottom: rem-calc(20);
    }
  }

  button {
    width: 100%;
    text-align: center;
  }

  > form {
    div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@mixin box-shadow($v-shadow: 1px, $h-shadow: 0px, $color: rgba(0,0,0,0.1)) {
  -webkit-box-shadow: $h-shadow $v-shadow 5px 0px $color;
  -moz-box-shadow: $h-shadow $v-shadow 5px 0px $color;
  box-shadow: $h-shadow $v-shadow 5px 0px $color;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin jumbotron(
  $padding: $spacing55 0 $spacing55 0
) {
  padding: $padding;
  text-align: center;

  form {
    margin: auto;
  }

  h2 {
    margin: auto;
    text-decoration: none;
    margin-bottom: $spacing34;
    font-size: rem-calc(26);

    @media #{$large-up} {
      font-size: rem-calc(30);
    }
  }

  h3 {
    margin: auto;
    font-size: rem-calc(18);
  }
}

@mixin prices() {
  > .old-price {
    text-decoration: line-through;
    display: inline-block;
  }

  > .sale-price {
    font-weight: bold;
    color: $red;
  }
}

@mixin border-width($top: $border-width, $right: $border-width, $bottom: $border-width, $left: $border-width) {
  border-width: $top $right $bottom $left;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-width: rem-calc($top) rem-calc($right) rem-calc($bottom) rem-calc($left);
  }
}