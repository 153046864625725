main {
  > section.features {
    @include full-width();
    @include jumbotron();

    > ul {
      @media #{$small-up} {
        @include block-grid(1);
        max-width: $row-width;
        margin: auto;
      }

      @media #{$medium-up} {
        @include block-grid(2);
        max-width: $row-width;
        margin: auto;
      }

      @media #{$large-up} {
        @include block-grid(4);
        max-width: $row-width;
        margin: auto;
      }

      > li {
        text-align: center;
        font-size: 90%;

        &.wishlist {;
          @include has-icon('heart-o', 0 rem-calc(30) rem-calc(30) rem-calc(30), 200%, block);
        }

        &.newsletter {
          @include has-icon('envelope-o', 0 rem-calc(30) rem-calc(30) rem-calc(30), 200%, block);
        }

        &.insider {
          @include has-icon('star-o', 0 rem-calc(30) rem-calc(30) rem-calc(30), 200%, block);
        }

        &.products {
          @include has-icon('diamond', 0 rem-calc(30) rem-calc(30) rem-calc(30), 200%, block);
        }
      }
    }
  }
}