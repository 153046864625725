@mixin post {
  display: block;
  position: relative;

  .column-group {
    display: flex;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    > li {
      width: 50%;
      padding: 0 $spacing5 $spacing5 0;

      @media #{$medium-up} {
        width: 25%;
      }

      > .product {
        @include product(false);

        margin: auto;
        width: 100%;
        height: 300px;

        @media #{$medium-up} {
          margin-left: 0;
        }

        > .image {
          max-width: rem-calc(250);
        }
      }
    }
  }

  &.format-gallery {
    > .wc-gallery {
      order: -1;
    }
  }

  .column-group {
    .column {
      @media #{$small-only} {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }

      &.column-number-1 {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  h2 {
    text-decoration: none;
    margin-left: 0;
    font-size: 1.75rem;
  }

  h3 {
    font-size: 14pt;
  }

  p {
    font-size: 110%;
    line-height: 1.6;

    &:empty {
      display: none;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul, ol {
    > li {
      font-size: 110%;
      margin-bottom: $spacing5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  figure {
    margin: 0;
    max-width: 100%;
  }

  hr {
    margin-top: $spacing13;
    margin-bottom: $spacing21;
  }

  .alignleft {
    display: inline;
    float: left;
    margin-right: $spacing8;
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: $spacing8;
  }

  > * {
    flex: 1 100%;
  }

  > header {
    margin-top: 0;
    margin-bottom: $spacing21;

    > h1 {
      margin-top: 0;
      text-align: center;
    }

    > .author-date {
      text-align: center;
      display: block;
    }

    > .teaser-image {
      width: 100%;

      @media #{$medium-up} {
        width: 100%;
        margin: 0 $spacing13 $spacing13 0;
      }
    }

    > .teaser-image-copyright {
      display: block;
      font-size: 8pt;
      color: $credit-color;
      margin-top: -1 * $spacing5;
      margin-bottom: $spacing8;
      font-style: italic;
    }
  }

  > footer {
    margin-top: $spacing21;

    > .title {
      display: block;
      font-weight: bold;
      text-align: center;
      margin-bottom: $spacing8;
    }
  }

  figcaption {
    font-size: rem-calc(10);
    color: $color-grey;
    margin-top: $spacing3;
  }

  > .shariff {
    margin-bottom: $spacing21;

    > ul {
      > li {
        width: auto;
        height: auto;
        margin-right: $spacing8;
        overflow: visible;

        > a {
          padding: 0;
          width: rem-calc(40);
          height: rem-calc(40);
          line-height: rem-calc(40);
          text-align: center;
          border-radius: 50%;
          overflow: hidden;

          > .fa {
            position: static;
          }

          > .share_text, > .share_count {
            display: none;
          }
        }
      }
    }
  }

  .sab-edit-settings {
    display: none;
  }
}
