section.wishlist {
  display: none;

  > h2 {
    @include has-icon('heart', 0 0 0 rem-calc(10));

    margin-left: 0;
    margin-top: $spacing34;
    margin-bottom: $spacing55;
    text-decoration: none;
    font-size: 150%;
    float: left;

    > i {
      color: $red;
    }
  }
  .insider-only {
    text-align: right;
    margin-top: $spacing21;
    margin-bottom: $spacing34;

    @media #{$medium-up} {
      float: right;
      margin-top: $spacing34;
      margin-bottom: $spacing55;
    }

    &:after {
      content: ' ';
      display: block;
      clear: both;
    }

    label {
      text-transform: uppercase;
      margin-top: $spacing5;
    }
  }

  .empty {
    @include grid-column(12);
  }
}