.price-range {
  span {
    @include has-icon('euro', 0);
    display: inline-block;
    text-align: center;
    line-height: rem-calc(30);
    width: rem-calc(20);
    opacity: 0.6;
  }

  &.low {
    .low {
      background-color: #ffffff;
      color: #000000;
      opacity: 1.0;
    }
  }

  &.moderate {
    .low, .moderate {
      background-color: #ffffff;
      color: #000000;
      opacity: 1.0;
    }
  }

  &.high {
    .low, .moderate, .high {
      background-color: #ffffff;
      color: #000000;
      opacity: 1.0;
    }
  }
}