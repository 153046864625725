body {
  main.wordpress_article {
    > .more-posts {
      > h3 {
        font-size: 120%;
        margin-bottom: $spacing21;
        padding-left: $spacing13;

        @media #{$medium-up} {
          padding-left: 0;
        }
      }

      padding-left: 0;
      padding-right: 0;

      @media #{$medium-up} {
        padding-left: $spacing13;
        padding-right: $spacing13;
      }

      .desktop-excerpts, .mobile-excerpts {
        .excerpt {
          > .thumbnail {
            width: 100%;
            display: block;
            background-size: cover;
            background-position: center center;
            font-size: 0;

            &:before {
              display: block;
              content: ' ';
              padding-top: 65.51%;
              width: 100%;
            }
          }

          > .info {
            padding: $spacing13 $spacing8 $spacing13 $spacing8;

            > header {
              > h3 {
                font-size: 100%;
                text-align: center;
              }
            }

            > footer {
              display: none;
            }
          }
        }
      }

      .desktop-excerpts {
        @include grid-row();

        display: none;
        margin-left: $column-gutter / -2;
        margin-right: $column-gutter / -2;
        width: auto;

        @media #{$medium-up} {
          display: block;
        }

        > .excerpt {
          @include grid-column(4);
          margin-bottom: $spacing34;
        }
      }

      .mobile-excerpts {
        display: block;

        @media #{$medium-up} {
          display: none;
        }

        > button {
          top: 35%;
        }
      }
    }
  }
}
