body {
  $size: $spacing34;
  $border-width: $spacing1;

  > a.scroll-up {
    @include has-icon('angle-up', 0);

    position: fixed;
    display: none;
    width: $size;
    height: $size;
    right: $spacing13;
    bottom: $spacing13;
    border-width: $border-width;
    border-style: solid;
    border-color: $color-darkpink;
    color: $color-darkpink;
    text-align: center;
    z-index: index($zIndexElements, scroll-up);
    background-color: rgba(255, 255, 255, 0.98);
    border-radius: $border-radius;
    font-size: 120%;

    @media #{$medium-up} {
      bottom: $spacing34;
    }

    i {
      line-height: $size - $border-width * 2;
    }
  }

  &.blog {
    > a.scroll-up {
      height: 0;
      width: 0;
      overflow: hidden;
    }

}

  &.general {
    > a.scroll-up {
      display: none !important;
    }
  }

  &.products {
    > a.scroll-up {
      background-color: $products-color;
    }
  }

  &.shops {
    > a.scroll-up {
      background-color: $shops-color;
    }
  }
}

