section.types {
  $thumbnail-size-large: rem-calc(180);
  $thumbnail-size-small: rem-calc(100);

  @include grid-row();
  @include full-width();

  > .products {
    @include column(12);

    > .content {
      max-width: $row-width;
      margin: auto;

      @media #{$medium-up} {
        padding-bottom: $spacing21;
      }

      > ul {
        @include block-grid(
          $per-row: 2,
          $spacing: #{$column-gutter / 2}
        );

        @media #{$medium-up} {
          @include block-grid(
            $per-row: 3,
            $spacing: $spacing13
          );
        }

        @media #{$large-up} {
          @include block-grid(
            $per-row: 6,
            $spacing: $spacing21
          );
        }

        margin: 0 !important;

        > li {
          text-align: center;
          position: relative;
          padding: 0 #{$column-gutter / 2} 0 #{$column-gutter / 2};
          margin-bottom: $spacing21;

          > a {
            margin: auto;
            font-size: 80%;

            &:hover {
              > .title {
                background-color: rgba(0,0,0, 0.6);
              }
            }

            &.thumbnail {
              position: relative;
              width: 100%;

              &:before {
                content : ' ';
                display: block;
                padding-top: 100%;
              }

              display: block;
              background-size: cover;
              background-position-x: center;
              background-position-y: center;
              border-radius: $border-radius;
            }

            > .title {
              transition: background-color 0.3s;
              display: inline-block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              background-color: rgba(0,0,0, 0.3);
              color: $color-white;
              padding: $spacing3 0 $spacing3 0;
            }
          }
        }
      }
    }

    .content {
      > ul {
        > li {
          a.thumbnail {
            border-color: $products-color;
          }
        }
      }
    }
  }
}