$font-family-sans-serif: 'Open Sans', sans-serif;
$font-weight-normal: 300;

$header-font-family:  'Lato', sans-serif;


$base-line-height: 1.5;

$small-breakpoint:  em-calc(400);
$medium-breakpoint: em-calc(800);
$large-breakpoint:  em-calc(1024);
$xlarge-breakpoint: em-calc(1920);

$medium-down: 'screen and (max-width:#{$medium-breakpoint})';

$fa-css-prefix: 'icon';
$fa-font-path: '../../fonts';

$spacing1: rem-calc(1);
$spacing2: rem-calc(2);
$spacing3: rem-calc(3);
$spacing5: rem-calc(5);
$spacing8: rem-calc(8);
$spacing13: rem-calc(13);
$spacing21: rem-calc(21);
$spacing34: rem-calc(34);
$spacing55: rem-calc(55);
$spacing89: rem-calc(89);
$spacing144: rem-calc(144);
$spacing244: rem-calc(233);
$spacing377: rem-calc(377);

$lightgrey: #fbfbfb;
$color-darkgrey: #555555;
$color-grey: #9A9A9A;
$color-mediumgrey: #E4E4E4;
$color-lightgrey: #f1f1f1;
$color-darkpink: #ea5093;
$color-rose: #f7e5f8;
$color-white: #ffffff;
$color-black: #000000;
$color-darkgrey-transparent: rgba(35, 35, 35, 0.75);
$color-mint: #8DCBB5;
$color-mint-02: rgba(141, 203, 181, 0.2);

$darkgrey: #232526;
$darkgrey-transparent: rgba(35, 37, 38, 0.9);
$general-color: #e7e7e7;
$general-color-dark: #d0d1d1;
$shops-color: #a9fddd;
$shops-color-dark: #9be7ca;
$products-color: #fdcba9;
$products-color-dark: #e7ba9c;
$darkturquois: #2b3d36;
$pink: #ea5093;
$darkpink: #e06982;
$red: #ff8181;
$lightgreen: #b2f9de;
$lightred: #fdcba9;
$rose: #f7e5f8;
$darkrose: #df6d85;

$zIndexElements:
  product-image,
  product-coupon,
  product-sale,
  scroll-up,
  social-icons,
  dropdown,
  category-bar-dropdown,
  header,
  cookie-bar,
  layer-background,
  off-canvas,
  off-canvas-close,
  off-canvas-list,
  layer,
  mobile-dropdown-layer,
  search-suggestions;

$products-color-transparent: rgba(253, 203, 169, 0.9);
$error-color: #ff8181;
$success-color: $shops-color;
$input-error-message-bg-color: $error-color;
$input-error-message-font-style: normal;
$input-error-message-font-color: #ffffff;
$input-error-message-font-color-alt: #ffffff;
$facebook-color: #3b5998;
$twitter-color: #4099FF;
$pinterest-color: #C92228;
$whatsapp-color: #43d854;
$mail-color: #c6c6c6;
$credit-color: #999999;
$border-radius: 2px;
$global-radius: $border-radius;

$base-font-size: 16px;
$paragraph-line-height: 1.5;
$rem-base: $base-font-size;

$input-height: rem-calc(50);
$row-width: rem-calc(1356);
$column-gutter: rem-calc(20);
$topbar-height: rem-calc(50);
$topbar-height-sticky: rem-calc(40);
$topbar-link-height: rem-calc(50);
$topbar-dropdown-link-height: rem-calc(34);
$topbar-link-height-sticky: $topbar-link-height/$topbar-height*$topbar-height-sticky;
$topbar-margin: rem-calc(12);
$topbar-bg: transparent;
$topbar-bg-color: transparent;
$topbar-link-bg-active: transparent;
$topbar-link-color: #000000;
$topbar-link-color-hover: inherit;
$topbar-link-color-active: #000000;
$topbar-link-color-active-hover: inherit;
$topbar-link-bg: transparent;
$topbar-link-bg-hover: transparent;
$topbar-link-bg-color-hover: transparent;
$topbar-link-bg-active-hover: transparent;
$topbar-link-padding: rem-calc(20);
$topbar-link-dropdown-padding: rem-calc(10);
$topbar-menu-link-color: #000000;
$topbar-menu-icon-color: #000000;
$topbar-link-text-transform: uppercase;
$topbar-link-font-size: rem-calc(14);
$topbar-link-weight: bold;
$topbar-dropdown-link-weight: bold;
$topbar-dropdown-bg: #ffffff;
$topbar-dropdown-link-bg: #ffffff;
$topbar-dropdown-link-color: #000000;
$topbar-dropdown-link-color-hover: inherit;
$topbar-dropdown-link-bg-hover: $topbar-dropdown-link-bg;

$header-top-bar-height: rem-calc(70);
$header-category-bar-height: rem-calc(40);
$header-social-bar-height: rem-calc(30);

$header-height-desktop: $header-social-bar-height + $header-top-bar-height + $header-category-bar-height;
$header-height-mobile: $header-social-bar-height + $header-top-bar-height;

$header-height-desktop-sticky: $header-height-desktop;

$border-width: 1px;

$button-bg-color: $rose;
$button-bg-hover: $darkrose;
$button-font-color: #ffffff;
$button-border-color: $darkgrey;
$button-border-width: 0;
$button-radius: $border-radius;
$button-med: rem-calc(10);
$button-font-med: rem-calc(12);

$header-border-width: rem-calc($border-width);
$header-padding: $spacing13;

$info-bar-sticky-height: rem-calc(30);
$footer-height: rem-calc(100);
$flash-height: rem-calc(60);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$anchor-font-color: #000000;
$anchor-font-color-hover: #5b5b5b;
$dropdown-height: rem-calc(47);
$block-grid-default-spacing: $column-gutter;

$h1-font-size: rem-calc(32);
$h2-font-size: rem-calc(28);
$h3-font-size: rem-calc(24);
$h4-font-size: rem-calc(20);
$h5-font-size: rem-calc(22);

$h1-font-reduction: rem-calc(10);
$h2-font-reduction: rem-calc(7);
$h3-font-reduction: rem-calc(5);
$h4-font-reduction: rem-calc(5);

$header-font-color: $color-darkgrey;
$body-font-color: $color-darkgrey;

