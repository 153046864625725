body {
  main.wordpress, main.wordpress_category {
    > section {
      > .posts {
        @include grid-row(nest);

        display: flex;
        flex-wrap: wrap;

        @media #{$small-only} {
          &:before {
            content: '2';
            display: none;
          }
        }

        margin-left: $column-gutter / -2;
        margin-right: $column-gutter / -2;
        margin-bottom: $spacing21;
        width: auto;

        &:after {
          content: ' ';
          display: block;
          clear: both;
        }

        > .masonry-column {
          width: 50%;
          float: left;

          > article {
            float: none;
            width: 100%;
          }
        }

        article {
          @include grid-column(6);

          margin-bottom: $spacing13;

          @media #{$medium-up} {
            @include grid-column(3);
          }

          > .thumbnail {
            width: 100%;
            display: block;
            background-size: cover;
            text-decoration: none;
            font-size: 0;

            &:before {
              display: block;
              content: ' ';
              padding-top: 66.67%;
              width: 100%;
            }
          }

          > .info {
            padding: $spacing8 $spacing5 $spacing8 $spacing5;
            text-align: center;

            @media #{$medium-up} {
              text-align: left;
              border-style: none;
            }

            > header {
              > h2 {
                text-decoration: none;
                font-size: 80%;
                margin: 0;
                font-weight: $font-weight-normal;
                font-family: $font-family-sans-serif;
                text-align: center;
              }
            }

            > footer {
              display: none;
            }
          }
        }
      }
    }
  }
}