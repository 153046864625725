ul.social-icons {
  list-style-type: none;
  margin: 0 0 $spacing13 0;
  text-align: center;

  > li {
    display: inline-block;
    margin-right: $spacing5;

    &:last-of-type {
      margin-right: 0;
    }

    > a {
      display: inline-block;
      width: rem-calc(40);
      height: rem-calc(40);
      text-align: center;
      font-size: 120%;
      line-height: rem-calc(40);
      border-radius: 50%;
      color: $white;
      opacity: 0.8;
      filter: grayscale(20%);
      transition: filter 0.3s, opacity 0.3s;
      transform: translate3d(0,0,0);

      &:hover {
        filter: none;
        opacity: 1;
        color: $white;
      }
    }

    &.facebook {
      > a {
        @include has-icon('facebook', 0 0 3px 0);

        background-color: $facebook-color;
      }
    }

    &.twitter {
      > a {
        @include has-icon('twitter', 0 0, 3px 0);

        background-color: $twitter-color;
      }
    }

    &.pinterest {
      > a {
        @include has-icon('pinterest', 0 0 3px 0);

        background-color: $pinterest-color;
      }
    }

    &.whatsapp {
      @media #{$medium-up} {
        display: none;
      }

      > a {
        @include has-icon('whatsapp', 0 0 3px 0);

        background-color: $whatsapp-color;
      }
    }

    &.mail {
      > a {
        @include has-icon('envelope', 0 0 3px 0);

        background-color: $mail-color;
      }
    }
  }
}
