section.badge {
  @include box();

  max-width: rem-calc(800);
  margin-bottom: $spacing8;

  @media #{$medium-up} {
    margin-bottom: $spacing8;
  }

  a {
    @extend .button;
    @include has-icon('envelope');
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 0;
  }

  img {
    margin-top: $spacing21;
    margin-bottom: $spacing21;
  }
}

section.legal {
  @include has-icon('warning');

  text-align: center;
  margin-bottom: $spacing8 + $footer-height;
  max-width: rem-calc(700);

  @media #{$medium-up} {
    margin-bottom: $spacing34 + $footer-height;
  }

  > i {
    color: $red;
  }
}