nav.breadcrumbs {
  line-height: 1.25;

  &.top {
    display: none;

    @media #{$medium-up} {
      display: block;
    }
  }

  &.bottom {
    display: block;
    margin-top: $spacing21;

    @media #{$medium-up} {
      display: none;
    }
  }

  > ol {
    list-style-type: none;
    margin: 0 0 $spacing13 0;
    padding: 0;
    font-size: 0;
    line-height: 1.25;

    > li {
      display: inline-block;
      line-height: 1.25;

      &:after {
        content: '»';
        display: inline-block;
        padding: 0 $spacing5 0 $spacing5;
        font-size: rem-calc(14);
        color: $color-grey;
      }

      &:last-child {
        &:after {
          display: none;
        }

        &:not(.linked) {
          > a {
            pointer-events: none;
            color: $color-grey;
          }
        }
      }

      > a {
        font-size: rem-calc(12);
        color: $color-grey;
        line-height: 1.25;

        @media #{$medium-up} {
          font-size: rem-calc(12.8);
        }
      }
    }
  }
}