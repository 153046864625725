.cookie-bar {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-darkgrey-transparent;
  color: $color-white;
  font-size: 80%;
  padding: $spacing8 $spacing8 $spacing34 $spacing8;
  z-index: index($zIndexElements, cookie-bar);

  @media #{$large-up} {
    padding: $spacing8;
  }

  > .accept {
    display: inline-block;
    color: $color-white;
    text-decoration: underline;
    position: absolute;
    left: $spacing8;
    bottom: $spacing8;

    @media #{$large-up} {
      position: static;
      padding-left: $spacing8;
    }
  }

  > .close {
    @include has-icon('close', 0);

    color: $color-white;
    font-size: 120%;
    position: absolute;
    right: $spacing8;
    bottom: $spacing8;

    @media #{$large-up} {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }
}