.sidebar-products {
  > aside {
    display: none;

    @media #{$large-up} {
      @include grid-column(2);

      display: block;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: $spacing8 0 0 $spacing13;
        font-size: 80%;

        a {
          color: $color-black;
          line-height: 1.25;

          &:hover {
            color: $color-darkpink;
            text-decoration: none;
          }
        }

        &.active {
          > a {
            color: $color-darkpink;
            font-weight: bold;
          }
        }

        > ul {
          @include border-width(0, 0, 0, 1px);

          margin-top: $spacing3;
          border-style: solid;
          border-color: $color-lightgrey;
          padding: 0 0 0 1px;

          > li {
            padding: $spacing3 0 $spacing3 $spacing8;
          }
        }
      }
    }

    > ul {
      > li {
        padding-left: 0;

        &:first-of-type {
          padding-top: 0;
        }
      }
    }

    > .sidebar-text {
      margin-top: $spacing21;
      font-size: 80%;

      p {
        font-size: 100%;
      }

      a {
        color: $color-black;
      }

      .highlight, .marker {
        color: $color-mint;
      }
    }

  }

}
