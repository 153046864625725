[data-scroll-fade] {
  transform: translateY(5%);
  opacity: 0;
  pointer-events: none;

  &.in-view {
    transition: transform 0.8s, opacity 0.8s;
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
}