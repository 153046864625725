html {
  > body {
    section.teaser {
      @include jumbotron(0 0 $spacing55 0);

      max-width: #{$row-width - $column-gutter};
      background-image: url(/img/home-teaser-mobile-summer.jpg);
      background-position: top right;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      padding: 0;
      min-height: 70vh;

      @media #{$medium-up} {
        background-image: url(/img/home-teaser-summer.jpg);
      }

      &:before {
        content: ' ';
        display: block;
        padding-top: 25%;
      }

      > img {
        width: rem-calc(100);
        height: rem-calc(100);
        margin-bottom: $spacing21;
      }

      > .headlines {
        position: absolute;
        bottom: 0;
        padding: #{$column-gutter / 2};
        width: 100%;
        left: 0;

        @media #{$medium-up} {
          top: 50%;
          bottom: auto;
          width: auto;
          padding: 0;
          left: #{$column-gutter * 4};
          transform: translateY(-50%);
        }

        > h1, > h2 {
          margin-top: 0;
          margin-bottom: 0;
          text-align: center;
          background-color: rgba(255, 255, 255, 0.7);
          max-width: none;

          @media #{$medium-up} {
            text-align: left;
            max-width: rem-calc(900);
            background-color: transparent;
          }
        }

        > h1 {
          font-size: 100%;
          color: $color-darkpink;
          padding: $spacing8 0 $spacing8 0;

          @media #{$medium-up} {
            font-size: 200%;
            max-width: rem-calc(400);
            margin-bottom: $spacing8;
            padding: 0;
          }
        }

        > h2 {
          text-transform: none;
          font-size: 80%;
          color: $color-darkgrey;
          padding-bottom: $spacing8;

          @media #{$medium-up} {
            font-size: 100%;
            max-width: rem-calc(700);
            padding-bottom: 0;
          }
        }
      }


      > form {
        $button-width: 100px;
        $textfield-width:  calc(100% - 100px);
        $input-height: 50px;

        margin: $spacing21 auto 0 auto;
        max-width: 500px;
        width: 90%;
        white-space: nowrap;
        font-size: 0;
        position: relative;
        display: none;

        > input[type=text] {
          border-radius: #{$border-radius * 2} 0 0 #{$border-radius * 2};
          font-size: rem-calc(16);
          width: $textfield-width;
          display: inline-block;
          height: $input-height;
          border-width: 1px 0 1px 1px;
          border-color: $color-rose;
          border-style: solid;
          padding-left: $spacing21;

          + .category-suggestions {
            position: absolute;
            left: 0;
            top: #{$input-height - $border-radius * 2};
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            background-color: rgba(255, 255, 255, 0.98);
            margin: 0;
            padding: 0;
            list-style-type: none;
            z-index: 4000;
            border-radius: 0 0 #{$border-radius * 2} #{$border-radius * 2};
            max-height: 0;
            opacity: 0;
            border-width: 0 1px 1px 1px;
            border-color: $color-rose;
            border-style: solid;

            &.visible {
              max-height: 30vh;
              opacity: 1;
            }

            > li {
              text-align: left;
              padding: $spacing5 $spacing8 $spacing5 $spacing8;
              border-width: 0;
              border-top-width: 1px;
              border-style: solid;
              border-color: $color-rose;
            }
          }
        }

        > button {
          font-size: rem-calc(16);
          width: $button-width;
          display: inline-block;
          border-radius: 0 #{$border-radius * 2} #{$border-radius * 2} 0;
          border-width: 1px 1px 1px 0;
          border-color: $color-rose;
          border-style: solid;
          height: $input-height;
        }
      }

      > a {
        &.register {
          @include button();
          @include box-shadow();

          opacity: 0;
          text-transform: uppercase;
          margin-top: $spacing34;
          max-width: 90%;
          background-color: rgba(255, 255, 255, 0.9);
          color: $black;
          border-width: 0;

          @media #{$medium-up} {
            max-width: 100%;
          }

          &:hover {
            color: $white;
            background-color: $black;
          }
        }

        &.arrow {
          margin: auto;
          position: absolute;
          bottom: rem-calc(-1);
          left: 0;
          right: 0;
          background-image: url(/img/teaser-arrow.png);
          background-size: auto 100%;
          width: rem-calc(100);
          height: rem-calc(50);
        }
      }
    }
  }

  &.touch {
    > body {
      section.teaser {
        background-attachment: scroll;
      }
    }
  }
}
