header {
  nav.category-bar {
    margin: auto;
    padding: 0 #{$column-gutter / 2} 0 #{$column-gutter / 2};
    height: $header-category-bar-height;
    display: none;
    max-width: none;
    text-align: center;

    @media #{$large-up} {
      display: block;
    }

    > ul {
      @include clearfix();

      list-style-type: none;
      margin: 0 auto 0 auto;
      padding: 0;
      max-width: $row-width - $column-gutter;

      > li {
        display: block;
        float: left;
        margin: 0 $spacing21 0 0;
        position: relative;
        height: $header-category-bar-height;
        text-align: left;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          > .dropdown {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.2s;
            transition-delay: 0.25s;
          }

          > a, > span {
            &:after {
              content: ' ';
              display: block;
              position: absolute;
              bottom: 6px;
              height: rem-calc(2);
              width: 100%;
              left: 0;
              background-color: $color-darkpink;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }

        > a, > span {
          cursor: pointer;
          line-height: $header-category-bar-height;
          height: $header-category-bar-height;
          display: inline-block;
          font-size: 80%;
          color: $color-black;
          position: relative;
        }

        > .dropdown {
          @include box-shadow(1px, 1px);

          position: absolute;
          left: $column-gutter * -1 ;
          top: #{$header-category-bar-height + rem-calc(1)};
          opacity: 0;
          height: auto;
          width: auto;
          pointer-events: none;
          white-space: nowrap;
          background-color: $color-white;
          display: block;

          &:before {
            content: ' ';
            top: rem-calc(-5);
            height: rem-calc(10);
            width: 100%;
            background-color: $color-white;
            position: absolute;
          }

          > ul {
            list-style-type: none;
            margin: #{$column-gutter / 2} $spacing21 $column-gutter $column-gutter;
            display: inline-block;
            padding: 0;
            vertical-align: top;

            &:last-of-type {
              margin-right: $column-gutter;
            }

            > li {
              margin: $spacing5 0 $spacing5 0;

              &.headline {
                font-weight: bold;
              }

              > a, > span {
                font-size: 80%;
                white-space: nowrap;
                color: $color-black;
              }

              > a {
                &:hover {
                  text-decoration: underline;
                  color: $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}
