body {
  &.has-mobile-layer, &.has-tablet-layer, &.has-layer {
    height: 100%;
    overflow-y: hidden;

    > .layer-background {
      opacity: 1;
      pointer-events: auto;
      height: 100%;
      overflow: hidden;
    }

    &.has-mobile-layer {
      @media #{$medium-up} {
        height: auto;
        overflow: auto;
      }

      > .layer-background {
        @media #{$medium-up} {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &.has-tablet-layer {
      @media #{$large-up} {
        height: auto;
        overflow: auto;
      }

      > .layer-background {
        @media #{$large-up} {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  > .layer-background {
    transition: opacity 0.3s;
    opacity: 0;
    display: block;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: index($zIndexElements, layer-background);
  }
}
