.smart-dropdown {
  $height: rem-calc(40);
  $submit-button-height: rem-calc(35);
  $search-input-height: rem-calc(30);
  $search-input-margin-bottom: $spacing5;
  $list-padding: $spacing8;

  display: block;
  border-color: $color-grey;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-radius: $border-radius;
  position: relative;
  transition: border-width 0.1s;

  &:last-child {
    border-width: 1px;
  }

  @media #{$medium-up} {
    border-width: 1px;
  }

  &.expanded {
    border-width: 1px 1px 0 1px;
    border-radius: $border-radius $border-radius 0 0 ;

    @media #{$small-only} {
      position: fixed;
      width: 90%;
      height: 80%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: $color-white;
      z-index: index($zIndexElements, mobile-dropdown-layer);
    }

    > .label {
      @media #{$medium-up} {
        @include has-icon('caret-up', 0);
      }

      @media #{$small-only} {
        @include has-icon('close', 0);

        > .name {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    > .list {
      pointer-events: auto;
      opacity: 1;

      @media #{$small-only} {
        height: calc(100% - #{$height});
      }

      > ul {
        @media #{$small-only} {
          -webkit-overflow-scrolling: touch;
          max-height: none;
          height: calc(100% - #{$submit-button-height
            + $search-input-height
            + $search-input-margin-bottom
            + $list-padding * 3
          });
        }
      }
    }
  }

  &.genders {
    > .list {
      > .search {
        display: none;
      }
    }
  }

  &.colors {
    > .list {
      > .search {
        display: none;
      }

      > ul {
        > li {
          display: inline-block;
          width: 22%;
          text-align: left;

          > input {
            &:checked {
              + label:before {
                display: block;
                border: none;
                margin: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                background-image: url(/img/icons/check_white.png);
                background-size: 50% 50%;
                background-position-x: center;
                background-position-y: center;
                background-repeat: no-repeat;
              }
            }
          }

          > label {
            padding: 0;
            display: inline-block;
            font-size: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: black;

            &:before {
              display: none;
            }
          }

          &[data-value=blue] {
            > label {
              background-color: #6787C6;
            }
          }

          &[data-value=brown] {
            > label {
              background-color: #A24A22;
            }
          }

          &[data-value=cyan] {
            > label {
              background-color: #4BC7BD;
            }
          }

          &[data-value=gray] {
            > label {
              background-color: #C8C8C8;
            }
          }

          &[data-value=green] {
            > label {
              background-color: #19A616;
            }
          }

          &[data-value=nude] {
            > label {
              background-color: #F7D8C0;
            }
          }

          &[data-value=nude] {
            > label {
              background-color: #F7D8C0;
            }
          }

          &[data-value=orange] {
            > label {
              background-color: #FF9E41;
            }
          }

          &[data-value=pink] {
            > label {
              background-color: #F31BF7;
            }
          }

          &[data-value=purple] {
            > label {
              background-color: #790BCD;
            }
          }

          &[data-value=red] {
            > label {
              background-color: #D93830;
            }
          }

          &[data-value=white] {
            > label {
              background-color: #FFFFFF;
              border-width: 1px;
              border-style: solid;
              border-color: $darkgrey;
            }
          }

          &[data-value=yellow] {
            > label {
              background-color: #FAED1C;
            }
          }
        }
      }
    }
  }

  > .label {
    @include has-icon('caret-down', 0);

    display: block;
    width: 100%;
    height: $height;
    line-height: $height;
    position: relative;
    padding: $spacing8;
    box-sizing: border-box;
    cursor: pointer;

    > .name {
      position: absolute;
      left: $spacing8;
      top: 52%;
      transform: translateY(-50%);
      font-size: 80%;
    }

    > i {
      position: absolute;
      right: rem-calc(15);
      font-size: 120%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  > .list {
    transition: opacity 0.3s;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: $height;
    left: -1px;
    right: -1px;
    z-index: index($zIndexElements, dropdown);
    background-color: $white;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $color-grey;
    border-radius: 0 0 $border-radius $border-radius;
    opacity: 0;
    padding: $list-padding $list-padding 0 $list-padding;

    > input {
      width: 100%;
      box-sizing: border-box;
      height: $search-input-height;
      font-size: 80%;
      padding: $spacing5;
      margin-bottom: $search-input-margin-bottom;

      &:focus {
        outline: none;
      }
    }

    > ul {
      list-style-type: none;
      max-height: 30vh;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0 0 $list-padding 0;
      padding: 0;

      > li {
        padding: $spacing3 0 $spacing3 0;

        &.hidden {
          display: none;
        }

        > label {
          margin: 0;
          font-size: 80%;
        }
      }
    }

    > button {
      width: 100%;
      background-color: $color-darkpink;
      color: $color-white;
      margin-bottom: $list-padding;
      height: $submit-button-height;
    }
  }
}