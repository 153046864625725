nav.pages {
  text-align: center;

 > ul {
   list-style-type: none;
   padding: 0;
   margin: 0 auto 0 auto;

   > li {
     display: inline-block;
     padding: $spacing5 $spacing8 $spacing3 $spacing8;


     &.active {
       pointer-events: none;
       font-weight: bold;

       > a {
         color: $color-darkpink;
         border-color: $color-darkpink;
       }
     }

     > a {
       display: inline-block;
       border-width: 1px;
       border-style: solid;
       border-color: $color-mint;
       color: $color-mint;
       border-radius: $border-radius;
       padding: $spacing3 $spacing13 $spacing3 $spacing13;
     }
   }
 }
}