body {
  $size: $spacing55;
  $border-width: $spacing5;

  > a.submit-form {
    @include has-icon('check');
    @include box-shadow();

    position: fixed;
    display: none;
    height: $size;
    color: $white;
    background-color: $black;
    border-width: $border-width;
    border-style: solid;
    border-color: $black;
    padding-left: $spacing8;
    padding-right: $spacing8;
    text-align: center;
    z-index: 9000;
    opacity: 0.95;
    line-height: $size - $border-width * 2;
    left: $spacing34;
    bottom: $spacing34;

    @media #{$medium-up} {
      display: none !important;
    }

    > i {
      line-height: $size - $border-width * 2;
    }

    &:hover {
      opacity: 1.0;
    }
  }
}

