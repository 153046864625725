body {
  > main {
    > section.wordpress-post {
      aside {
        background-color: rgba(255, 255, 255, 0.7);
        padding-left: $spacing13;

        > section {
          margin-bottom: $spacing13;

          h2 {
            margin-left: 0;
            text-decoration: none;
            font-size: 120%;
            font-weight: normal;
            margin-bottom: $spacing21;
          }

          ul {
            margin-left: 0;
            list-style: none;

            > li {
              @include border-width(1px, 0, 0, 0);

              padding: $spacing5 0 $spacing5 0;
              border-style: solid;
              border-color: #d0d1d1;
              height: rem-calc(60);
              position: relative;
              font-size: 80%;

              > div {
                > * {
                  display: none;
                }

                > a.cat-post-title {
                  display: block;
                  width: 75%;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  font-weight: normal;
                  height: auto;
                  padding-right: $spacing8;
                }

                > a.cat-post-thumbnail {
                  display: block;
                  width: 25%;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  padding: 0;
                  height: 70%;

                  > span {
                    position: absolute;
                    width: 100% !important;
                    height: 100% !important;

                    img {
                      position: absolute;
                      width: auto !important;
                      height: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}