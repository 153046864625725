body.shops {
  > main {
    section.teaser-small {
      margin-bottom: 0;
    }

    section.shops, section.wishlist {
      padding-bottom: $spacing55;

      ul {
        &.filters {
          @include block-grid(
                  $per-row: 1,
                  $spacing: rem-calc(16)
          );

          @media #{$medium-up} {
            @include block-grid(
                    $per-row: 3,
                    $spacing: rem-calc(16)
            );

            margin-bottom: $spacing21;
          }
        }

        &.shops {
          @include block-grid(
                  $per-row: 1,
                  $spacing: rem-calc(16)
          );

          @media #{$medium-up} {
            @include block-grid(
                    $per-row: 2,
                    $spacing: rem-calc(16)
            );
          }

          @media #{$large-up} {
            @include block-grid(
                    $per-row: 3,
                    $spacing: rem-calc(16)
            );
          }

          li {
            position: relative;
            margin-top: $spacing21;

            @media #{$medium-up} {
              margin-top: 0;
            }

            > img.coupon {
              position: absolute;
              margin-top: 0;
              margin-left: 0;
              z-index: 100;
              width: rem-calc(40);
              height: rem-calc(40);
            }

            form {
              margin: auto;
              padding: rem-calc(8);
              padding-bottom: 0;
              background-color: $darkgrey;
              position: relative;
              min-height: rem-calc(384);
              text-align: center;

              > h3 {
                font-size: rem-calc(18);
                color: #ffffff;
                margin-top: rem-calc(5);
                margin-bottom: rem-calc(2);
              }

              > img {
                display: block;
                margin: auto;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin-bottom: 0; // IE + Edge adds margin to img
                height: rem-calc(310);
                width: rem-calc(340);
              }

              > .gradient {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                margin: auto;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: rem-calc(310);
                width: rem-calc(340);
                opacity: 0.8;
              }

              .remove-from-wishlist {
                @include has-icon('heart', 0, 100%, block);
                display: none;
                width: rem-calc(50);
                height: rem-calc(50);
                position: absolute;
                right: 0;
                top: 0;
                color: $red;

                i {
                  display: none;
                  position: absolute;
                  z-index: 2;
                  right: 5px;
                  top: 5px;
                }

                .triangle {
                  z-index: 1;
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 0;
                  height: 0;
                  border-left: rem-calc(50) solid transparent;
                  border-right: 0 solid transparent;
                  border-top: rem-calc(50) solid #fff;
                }

              }

              > .info {
                z-index: 999;
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: rem-calc(320);
                background-color: $darkgrey-transparent;
                color: #ffffff;
                cursor: pointer;

                div {
                  width: 100%;
                  text-align: left;
                  position: absolute;
                  padding-left: $spacing34;
                  padding-right: $spacing34;

                  &.ranges {
                    top: $spacing5;

                    > span {
                      @include has-icon('euro', 0);
                      display: inline-block;
                      height: rem-calc(30);
                      line-height: rem-calc(30);
                      border-style: solid;
                      border-width: 1px;
                      border-color: #ffffff;
                      text-align: center;
                      padding-left: $spacing8;
                      padding-right: $spacing8;

                      > i {
                        width: rem-calc(15);
                      }
                    }
                  }

                  &.description {
                    top: $spacing55;

                    > .coupon {
                      > .code {
                        > span {
                          cursor: text;
                        }
                      }
                    }
                  }

                  &.links {
                    bottom: $spacing55;

                    a, button {
                      @include button();

                      background-color: transparent;
                      color: #ffffff;
                      text-transform: uppercase;
                      border-width: 1px;
                      border-color: #ffffff;
                      border-style: solid;
                      width: 47%;
                      padding-left: 0;
                      padding-right: 0;

                      &.shop {
                        float: left;
                      }

                      &.add-to-wishlist {
                        @include has-icon('heart-o');

                        float: right;

                        &:hover {
                          i {
                            color: $red;
                          }
                        }

                        &:after {
                          content: ' ';
                          display: block;
                          clear: both;
                        }
                      }

                      &:hover {
                        color: #000000;
                        background-color: rgba(240, 240, 240, 0.8);
                      }
                    }
                  }

                  &.products {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background-color: $products-color;
                    height: rem-calc(50);
                    line-height: rem-calc(50);
                    color: #000000;
                    opacity: 0.9;

                    > a {
                      @include has-icon('long-arrow-right', 0);
                      position: absolute;
                      top: 0;
                      right: $spacing21;
                    }
                  }
                }
              }

              .recommendation {
                @include border-width($border-width, 0, 0, 0);

                z-index: 500;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.7);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.9) 100%);
                padding-bottom: $spacing13;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                border-style: solid;
                border-color: #ffffff;
                color: #ffffff;

                > img {
                  @include box-shadow();
                  @include border-width();

                  width: rem-calc(50);
                  margin-top: rem-calc(27)*-1;
                  border-radius: 50%;
                  border-style: solid;
                  border-color: #ffffff;
                }

                > .recommender {
                  font-size: 80%;
                }
              }

              .coupons {
                @include box-shadow();
                display: none;
                position: absolute;
                top: -2%;
                left: -2%;
                width: 104%;
                height: 134%;
                border-style: solid;
                border-width: rem-calc(10);
                border-color: $red;
                z-index: 2000;
                pointer-events: none;
                transition: all 2s;

                > .info {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  background-color: $red;
                  color: #ffffff;
                  width: 100%;
                  padding-top: $spacing21;
                  padding-bottom: $spacing13;
                  pointer-events: auto;
                  height: 100px;

                  > span {
                    &.code {
                      text-transform: uppercase;
                    }

                    &.description {
                      @include has-icon('euro', 0);

                      font-size: 90%;
                    }

                  }
                }
              }

              &.active {
                > .info {
                  display: block;
                }

                > .coupons {
                  display: block;
                }

                > .recommendation {
                  display: none;
                }
              }
            }

            &.insider {
              section {
                @include border-width();

                min-height: rem-calc(384);
                position: relative;
                background-color: #eeeeee;
                background-image: url(/img/insider.jpg);
                background-size: cover;
                padding: $spacing8;
                cursor: pointer;
                border-style: solid;
                border-color: $black;

                h3 {
                  position: absolute;
                  margin: auto;
                  left: 0;
                  right: 0;
                  top: 40%;
                  font-size: rem-calc(20);
                  color: $darkgrey;
                  text-align: center;

                  .count {
                    font-size: rem-calc(40);
                  }
                }

                .login-register {
                  @extend .button;

                  margin: 0;
                  position: absolute;
                  bottom: $spacing13;
                  left: $spacing13;
                  right: $spacing13;
                }
              }
            }

            &.is-insider {
              form {
                background-color: $lightred;

                h3 {
                  color: $darkgrey;
                }

                h4 {
                  color: $darkgrey;
                  text-transform: none;
                  font-size: rem-calc(16);
                }

                > .info {
                  background-color: $products-color-transparent;
                  color: #000000;

                  div {
                    &.ranges {
                      span {
                        border-color: $darkgrey;
                      }

                      &.low {
                        .low {
                          background-color: $darkgrey;
                          color: #ffffff;
                          opacity: 1.0;
                        }
                      }

                      &.moderate {
                        .low, .moderate {
                          background-color: $darkgrey;
                          color: #ffffff;
                          opacity: 1.0;
                        }
                      }

                      &.high {
                        .low, .moderate, .high {
                          background-color: $darkgrey;
                          color: #ffffff;
                          opacity: 1.0;
                        }
                      }
                    }

                    &.links {
                      a, button {
                        border-color: $darkgrey;
                        color: $darkgrey;
                      }
                    }
                  }
                }
              }
            }

            &.added-to-wishlist {
              .remove-from-wishlist {
                display: block;

                i {
                  display: block;
                }

                &:hover {
                  @include has-icon('heart-o', 0, 100%, block);
                }
              }

              .links {
                > .shop {
                  width: 100%;
                }

                > .add-to-wishlist {
                  display: none;
                }
              }
            }
          }
        }
      }

      &.is-guest {
        ul.shops .info .links a.shop {
          width: 100%;
        }
      }

      form.filters {
        @media #{$medium-up} {
          &.logged-in {
            margin-top: -1*rem-calc(81);
          }
        }

        .insider-only {
          text-align: left;
          margin-bottom: $spacing34;

          @media #{$medium-up} {
            text-align: right;
          }

          label {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}