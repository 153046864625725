@import "products/sub-categories";
@import "products/sidebar";
@import "products/pages";

html {
  &.no-touch {
    .sidebar-products {
      > section.filters-products {
        .products {
          >li {
            .product {
              @include product(true);
            }
          }
        }
      }
    }
  }

  .sidebar-products {
    @include grid-row();

    > section.filters-products {
      @include grid-column(12);

      @media #{$large-up} {
        @include grid-column(10);
      }

      h1 {
        $h1-height-mobile: rem-calc(30);
        $h1-height-desktop: rem-calc(35);

        margin-top: 0;
        margin-bottom: $spacing13;
        line-height: $h1-height-mobile;
        height: $h1-height-mobile;
        font-size: rem-calc(22);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.is-child {
          margin-left: #{$spacing5 * -1};

          @media #{$medium-up} {
            margin-left: -1px;
          }
        }

        @media #{$medium-up} {
          line-height: $h1-height-desktop;
          height: $h1-height-desktop;
          margin-bottom: $spacing13;
          font-size: rem-calc(30);
        }

        > a {
          @include has-icon('angle-left', 0);

          color: $color-black;
          position: relative;
          display: inline-block;
          width: rem-calc(25);
          height: $h1-height-mobile;

          > i {
            position: absolute;
            top: 50%;
            left: #{$spacing5};
            transform: translateY(-50%);
            font-size: rem-calc(22);
          }

          @media #{$medium-up} {
            display: none;
          }
        }
      }

      .products {
        @include grid-row();

        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin-left: $column-gutter / -2;
        margin-right: $column-gutter / -2;
        width: auto;

        > li {
          @include grid-column(6);

          position: relative;
          margin-bottom: $spacing21;
          float: none;

          @media #{$medium-up} {
            @include grid-column(3);
          }

          > .product {
            @include product(false);
          }
        }
      }

      > .load-more {
        text-align: center;

        > button {
          margin: auto;
          margin-bottom: $spacing55;
          width: auto;
        }
      }
    }
  }
}
