section.intro {
  max-width: rem-calc(1000);
  opacity: 0.8;
  margin-bottom: $spacing55;

  h2 {
    font-size: 120%;
    margin: 0 0 $spacing8 0;
    text-decoration: none;
    text-align: center;
  }

  h3 {
    font-size: 100%;
    margin: 0 0 $spacing5 0;
    text-align: center;
  }

  p {
    font-size: 80%;
  }

  a {
    color: $color-black;
  }
}
