@import "article/gallery";
@import "article/more-posts";
@import "article/related-posts";
@import "article/author-box";
@import "article/amazon-list";

html {
  &.no-touch {
    body {
      > main {
        > section.wordpress-page, > section.wordpress-post {
          article.post, article.page {
            .products {
              > li {
                > .product {
                  @include product(true);
                }
              }
            }
          }
        }
      }
    }
  }

  body {
    > main {
      > section.wordpress-page, > section.wordpress-post {
        @include clearfix();

        &.wordpress-page {
          max-width: 920px;
          margin: auto;

          article.page {
            float: none;
            text-align: left;
            width: 100%;
          }
        }

        article.post, article.page {
          @include post();

          @media #{$large-up} {
            width: 66.67%;
            float: left;
          }
        }

        > .sticky-wrapper, > aside {
          margin-bottom: $footer-height;
          float: right;
          width: 25%;
          display: none;

          @media #{$large-up} {
            display: block;
          }

          h2 {
            margin-top: 0;
            line-height: 1;
          }

          + hr {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
