.slider {
  position: relative;
  overflow: hidden;
  display: block;
  animation-duration: 3s;
  animation-delay: 2s;

  > .slides {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 0;
    padding-bottom: 20px;

    > .slide {
      display: inline-block;
      overflow: hidden;
      margin: 0 ($column-gutter / 2) 20px ($column-gutter / 2);
      width: 35vw;
      font-size: 8pt;
      white-space: normal;
      vertical-align: top;

      @media #{$medium-up} {
        width: 20%;
      }

      img[data-lazy] {
        display: none;
      }
    }
  }

  > button {
    background: none;
    color: $black;
    text-align: center;
    opacity: 0.3;
    top: 40%;
    margin: 0;
    display: none;
    border: none;
    font-size: 140%;
    position: absolute;
    padding: 0;

    @media #{$medium-up} {
      display: block;
    }

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    &.prev {
      @include has-icon('chevron-circle-left', 0);
      left: $spacing13;
    }

    &.next {
      @include has-icon('chevron-circle-right', 0);
      right: $spacing13;
    }

    &[disabled] {
      display: none;
    }

    > i {
      @include has-icon('fw');
    }
  }
}