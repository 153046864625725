body {
  section.teaser-small {
    @include full-width;
    @include jumbotron(0 0 $spacing55 0);

    text-align: center;
    padding: 0;
    padding-top: $spacing34;
    padding-bottom: $spacing34;
    background-size: cover;
    margin-bottom: $spacing21;
    background-color: #545454;
    background-image: url(/img/teasers/small.jpg);

    > h1, > h2 {
      color: #ffffff;
      text-align: center;
      margin: 0;
    }

    > h1 {
      font-size: 240%;
    }

    > h2 {
      font-weight: normal;
      font-size: 100%;
      text-transform: none;
      max-width: rem-calc(700);
      margin: auto;
    }

    > a.register {
      @extend .button;
      opacity: 0;
      text-transform: uppercase;
      margin-top: $spacing34;
      max-width: 90%;
      background-color: rgba(255, 255, 255, 0.9);
      color: $black;

      &:hover {
        color: $white;
        background-color: $black;
      }

      @media #{$medium-up} {
        max-width: 100%;
      }
    }
  }
}