.sub-categories {
  $bar-height: rem-calc(34);

  display: block;
  margin-bottom: $spacing21;
  height: $bar-height;
  overflow-y: hidden;

  @media #{$large-up} {
    display: none;
  }

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;
    height: #{$bar-height + rem-calc(20)};
    -webkit-overflow-scrolling: touch;
    font-size: 0;

    > li {
      display: inline-block;
      height: $bar-height;
      margin-right: $spacing8;

      &.active {
        > a {
          font-weight: bold;
          border-width: 2px;
          height: #{$bar-height - rem-calc(4)};
          line-height: #{$bar-height - rem-calc(4)};
        }
      }

      > a {
        box-sizing: content-box;
        font-size: rem-calc(14);
        height: #{$bar-height - rem-calc(2)};
        line-height:  #{$bar-height - rem-calc(2)};
        padding: 0 $spacing8 0 $spacing8;
        border-width: 1px;
        border-style: solid;
        border-color: $color-darkpink;
        border-radius: $border-radius;
        display: inline-block;
      }
    }
  }

}