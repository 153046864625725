section.my-data {
  @include box();

   .email, .password, .newsletter, .current-password {
     @include grid-row();
   }

  .email {
    .change {
      font-size: 80%;
    }
  }

  .cancel {
    text-align: center;
  }
 }